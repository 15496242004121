import * as React from "react"
import "../index.css"

import { Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

import { Box, Center, Flex, Grid, Image } from "@chakra-ui/react"
import {
  aboutUsPage,
  awardedContents,
  contents,
} from "../../utils/descriptions"
import Footer from "../Home/Footer"
import HomeNavBar from "../Home/HomeNavBar"
import { welaMisVis } from "../../utils/descriptions"
import Ads from "../Home/Ads"

import aboutusImg from "../../images/aboutUs.png"
import awards1 from "../../images/awards1.png"
import awards2 from "../../images/awards2.png"
import Media from "./media"

const AboutUs = () => {
  return (
    <>
      <HomeNavBar />
      <Box
        w="100%"
        p={4}
        px={{ "2xl": "12rem", xl: "8rem", lg: "5rem", base: "2rem" }}
        bgColor="#FBFBFB"
        color="white"
      >
        <Grid
          templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
          gap={{ xl: 24, lg: 12, base: 4 }}
          color="white"
          mx={{ "2xl": "0.5", xl: "0.5", lg: "0.5", sm: "6rem" }}
        >
          <Box mt={{ "2xl": "7rem", xl: "4rem", lg: "5rem", base: "1rem" }}>
            <Box
              fontWeight={500}
              fontSize={{ lg: 18, base: 16 }}
              color="#8326EA"
              letterSpacing={"wider"}
              py="2"
            >
              <Trans>Who we are</Trans>
            </Box>
            <Box
              fontSize={{ "2xl": 45, xl: 35, lg: 30, base: 22 }}
              fontWeight={600}
              color="#3952D3"
              mb="3"
            >
              <Trans>{aboutUsPage.title}</Trans>
            </Box>
            <Box fontSize={{ "2xl": 17, xl: 15, lg: 14, sm: 14 }} color="black">
              <Trans>{aboutUsPage.desc}</Trans>
            </Box>
          </Box>
          <Image
            src={aboutusImg}
            alt="banner"
            w="70%"
            display={["none", "none", "none", "block", "block"]}
            mb="0"
            my="auto"
          />
        </Grid>
      </Box>

      <Box py="12">
        <Box mx={{ xl: "8rem", lg: "5rem", base: "0.5rem" }}>
          <Box mb={{ lg: "16", base: "0" }} mt={{ xl: "6", lg: "8", base: 0 }}>
            <Center>
              <Box
                fontWeight={500}
                fontSize={{ lg: 18, base: 16 }}
                color="#8326EA"
                mb="2"
                letterSpacing={"wider"}
              >
                Our story
              </Box>
            </Center>
            <Center>
              <Box
                fontWeight={{ xl: 700, base: 600 }}
                fontSize={{ xl: 40, lg: 30, base: 20 }}
                color="#000"
              >
                <Trans>How It Started</Trans>
              </Box>
            </Center>
            <Box
              mt={{ lg: "4", base: "2" }}
              fontSize={15}
              mx={{
                xl: "10rem",
                lg: "5rem",
                sm: "2rem",
                base: "1rem",
                "2xl": "18rem",
              }}
            >
              <Center>
                <Box
                  textAlign={"center"}
                  fontWeight={500}
                  fontSize={{ lg: 20, base: 18 }}
                >
                  <Trans>{contents.aboutUs1}</Trans>
                </Box>
              </Center>
              <Center>
                <Box
                  mt={5}
                  textAlign={"center"}
                  fontWeight={500}
                  fontSize={{ lg: 20, base: 18 }}
                >
                  <Trans>{contents.aboutUs2}</Trans>
                </Box>
              </Center>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        bgGradient="linear(to-l, #B102FE, #0670C3)"
        py="14"
        position="relative"
      >
        <Box mx={{ xl: "8rem", lg: "5rem", base: "1rem" }}>
          <Box
            position="absolute"
            h="1.5"
            w={{
              "2xl": "200px",
              xl: "170px",
              lg: "150px",
              sm: "180px",
              base: "80px",
            }}
            bgColor={"white"}
            transform={{ lg: "rotate(90deg)" }}
            left={{ lg: "45%", sm: "41%", base: "39%" }}
            top={{ lg: "50%", sm: "48%", base: "53%" }}
          />
          <Grid
            templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
            gap={{ lg: 36, base: 4 }}
            color="white"
            mx={{ "2xl": "0.5", xl: "0.5", lg: "0.5", sm: "6rem" }}
          >
            <Box
              mb={{ lg: "16", base: "0" }}
              mt={{ xl: "6", lg: "8", base: 0 }}
              mx={{ "2xl": "7rem", xl: "4rem", lg: "3rem", base: "0.5rem" }}
            >
              <Box
                fontWeight={600}
                fontSize={{ xl: 35, lg: 30, base: 20 }}
                color="white"
              >
                <Center>
                  <Trans>Our Mission</Trans>
                </Center>
              </Box>
              <Box mt={{ lg: "4", base: "2" }} fontSize={15}>
                <Center>
                  <Box textAlign={"center"}>
                    <Trans>{welaMisVis.mission}</Trans>
                  </Box>
                </Center>
              </Box>
            </Box>
            <Box
              mb={{ lg: "16", base: "0" }}
              mt={{ xl: "6", lg: "8", base: "16" }}
              mx={{ "2xl": "7rem", xl: "4rem", lg: "3rem", base: "0.5rem" }}
            >
              <Box
                fontWeight={600}
                fontSize={{ xl: 35, lg: 30, base: 20 }}
                color="white"
              >
                <Center>
                  <Trans>Our Vision</Trans>
                </Center>
              </Box>
              <Box mt={{ lg: "4", base: "2" }} fontSize={15}>
                <Center>
                  <Box textAlign={"center"}>
                    <Trans>{welaMisVis.vission}</Trans>
                  </Box>
                </Center>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box mt={20} mb={10} mx={{ xl: "12rem", lg: "8rem", base: "1rem" }}>
        <Center>
          <Box
            fontWeight={600}
            fontSize={{ lg: 18, base: 16 }}
            color="#8326ea"
            letterSpacing={"wider"}
          >
            Awards
          </Box>
        </Center>
        <Box
          fontSize={{ "2xl": 45, xl: 35, lg: 25, sm: 20, base: 20 }}
          textAlign={"center"}
          fontWeight={{ lg: 700, base: 600 }}
          py="3"
        >
          <Trans>Our Accomplishments</Trans>
        </Box>
        <Box
          mx={{ xl: "7rem", lg: "4rem", sm: "2rem", base: "1rem" }}
          textAlign={"center"}
          fontSize={{ lg: 20, base: 18 }}
        >
          <Trans>{awardedContents[0].desc}</Trans>
        </Box>
      </Box>
      <Box>
        <Flex justifyContent={"center"} my="10" wrap={"wrap"}>
          <Box>
            <Image
              src={awards1}
              w={{ xl: "100%", lg: "85%", base: "90%" }}
              mb="0"
              mr="0"
              mx="auto"
            />
          </Box>
          <Box>
            <Image
              src={awards2}
              w={{ xl: "100%", lg: "85%", base: "90%" }}
              mb="0"
              mr="0"
              mx="auto"
            />
          </Box>
        </Flex>
      </Box>

      <Media />

      <Ads />

      <Footer />
    </>
  )
}

export default AboutUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
